import dayjs from '../helpers/date-helpers';
import { Timestamp } from '@google-cloud/firestore';

export const kgInPounds = 2.20462262185;
export const poundInKg = 0.45359237;
export const gaTrackingId = 'UA-125896490-1';
export enum DiscountTypes {
    PERCENT = 'percent',
    AMOUNT = 'amount',
    SET_PRICE = 'set-price',
}

export const gaPageview = (url) => {
    window['gtag']('config', gaTrackingId, {
        page_location: url,
    });
};

export const gaEvent = ({ action, category, label, value }) => {
    window['gtag']('event', action, {
        event_category: category,
        event_label: label,
        value,
    });
};

export const checkIfActivePage = (url: string, key: string): string =>
    url.includes(key) ? 'active' : '';

export const roundTo = (value: number, decimalPlaces: number): number => {
    const manipulator = 10 ** decimalPlaces;

    return Math.round(value * manipulator) / manipulator;
};

export const convertToClassNameString = (classNamesArray: Array<string>): string =>
    classNamesArray
        .filter((className: string) => className !== '')
        .join(' ')
        .trim();

export const capitalizeString = (value: string): string =>
    value.charAt(0).toUpperCase() + value.slice(1);

export const slugify = (value: string): string =>
    value
        .toLowerCase()
        .trim()
        .split(' ')
        .join('-')
        .replace(/[^a-zA-Z0-9-_]/g, '')
        .replace(/[-]+/g, '-');

export const generateId = (prefix: string): string =>
    `${prefix}-${Math.random().toString(36).substr(2, 5)}`;

export const calculateDiscount = (
    price: number,
    discountAmount: number,
    discountType: DiscountTypes
) => {
    switch (discountType) {
        case DiscountTypes.PERCENT:
            return Math.round(price * (discountAmount / 100));
        case DiscountTypes.AMOUNT:
            return discountAmount;
        case DiscountTypes.SET_PRICE:
            return Math.round(price - discountAmount);
        default:
            return 0;
    }
};

export const stringifyCurrency = (amount: number): string => {
    const stringAmount = amount.toString();
    const euros = stringAmount.slice(0, -2);
    const cents = stringAmount.slice(-2);

    return cents === '00' ? euros : `${euros}.${cents}`;
};

export const convertParamsToObject = (location: Location): { [key: string]: string } => {
    const paramString = location.href.split('?')[1];
    const myregexp = /([\w]+)=([^&,#]+)/g;
    const paramsObject = {};
    let match = myregexp.exec(paramString);

    while (match !== null) {
        const [, key, value] = match;
        paramsObject[key] = decodeURIComponent(value);
        match = myregexp.exec(paramString);
    }

    return paramsObject;
};

export const formatBytes = (bytes: number, decimals?: number): string => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const getPlanInfo = (products) => {
    const planInfo = {
        planCode: null,
        planPeriod: null,
        activeFrom: null,
        activeTo: null,
        workoutWeek: null,
        isGymPlan: false,
    };

    Object.keys(products).some((planCode) => {
        const plan: { activeTo: Timestamp; activeFrom: Timestamp } = products[planCode];
        const { activeTo, activeFrom } = plan;

        if (plan && activeTo) {
            const planPeriod = parseFloat(planCode.replace(/\D/g, ''));
            const halfwayMark = dayjs(activeFrom.toDate())
                .add(planPeriod / 2, 'week')
                .subtract(1, 'day');
            const cutOffPoint = dayjs(activeTo.toDate()).add(2, 'week');

            if (dayjs().isAfter(cutOffPoint)) {
                return planInfo;
            }

            planInfo.planCode = planCode;
            planInfo.planPeriod = planPeriod;
            planInfo.activeFrom = activeFrom.toDate();
            planInfo.activeTo = activeTo.toDate();

            if (dayjs().isBefore(halfwayMark)) {
                planInfo.workoutWeek = 'first-half';
            } else if ((planPeriod === 12 || planPeriod === 8) && dayjs().isBefore(cutOffPoint)) {
                planInfo.workoutWeek = 'second-half';
            }

            const gymPlans = ['OC6W', 'OC8W', 'OC12W'];
            planInfo.isGymPlan = gymPlans.indexOf(planCode) >= 0;

            return true;
        }

        return false;
    });

    return planInfo;
};

const footInCM = 30.48;
const inchInCM = 2.54;
// Small tolerance for floating-point
const tolerance = 0.005;

export const convertCMToFeetAndInches = (cm: number): { feet: number; inches: number } => {
    const feet = Math.floor(cm / footInCM);
    const inches = (cm - feet * footInCM) / inchInCM;
    return { feet, inches: parseFloat((inches + tolerance).toFixed(2)) };
};

export const convertFeetAndInchesToCM = (feet: number, inches: number): number => {
    const cm = feet * footInCM + inches * inchInCM;
    return parseFloat((cm - tolerance).toFixed(2));
};

export const convertMinsToHoursAndMinsString = (totalWorkoutMinutes) => {
    if (totalWorkoutMinutes === undefined) {
        return '0mins';
    }

    const hours = Math.floor(totalWorkoutMinutes / 60);

    return hours > 0
        ? `${hours}hrs ${totalWorkoutMinutes - hours * 60}mins`
        : `${totalWorkoutMinutes}mins`;
};

/**
 * this helper function returns date in given timezone
 * e.g. if admin is logged in as other user, then this will return
 * date based on other user timezone
 * @param date string
 * @param timeZone user string
 * @returns date in YYYY-MM-DD format
 */
export const getIntlDate = (date, timeZone) => {
    const newDate = new Intl.DateTimeFormat('en-US', {
        timeZone: !timeZone ? undefined : timeZone,
    }).format(date);
    const dateFormat = dayjs(newDate).format('YYYY-MM-DD');
    return dateFormat;
};

export const convertKgToPound = (weight: number) => {
    const weightInKg = Number(weight) || 0;
    const weightInPound = weightInKg * kgInPounds;
    return Number(weightInPound.toFixed(2));
};

export const convertPoundToKg = (weight: number) => {
    const weightInPound = Number(weight) || 0;
    const weightInKg = weightInPound * poundInKg;
    return Number(weightInKg.toFixed(2));
};

export const getWeekDates = (date) => {
    const daysOfWeek = [];
    for (let i = 0; i < 7; i++) {
        const day = date.clone().add(i, 'day');
        daysOfWeek.push(day.format('YYYY-MM-DD'));
    }
    return daysOfWeek;
};

export const isDuplicateValue = (arr, key, value) => {
    if (!value || !key || !arr?.length) return false;
    const lowerNewName = value.toLowerCase();
    const isDuplicate = arr.some((ex) => (ex[key] || '').toLowerCase() === lowerNewName);
    return isDuplicate;
};
